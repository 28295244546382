import { debounce, removeTrapFocus, trapFocus } from '@/scripts/setup'
import {
	currentTargetRequired,
	getAttributeOrThrow,
	qsOptional,
	qsRequired,
} from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { type HeaderDrawer } from '@/scripts/layout/HeaderDrawer'

export class SlideDrawer extends UcoastEl {
	static htmlSelector = 'slide-drawer'
	drawerId: string
	drawerEl: HTMLElement
	drawerContent: HTMLElement
	openButton: HTMLButtonElement
	headerDrawer: HeaderDrawer
	predictiveSearch?: HTMLElement
	facetsForm?: HTMLElement
	closeAllButton?: HTMLButtonElement

	constructor() {
		super()
		this.drawerId = getAttributeOrThrow('data-uc-drawer-id', this)
		this.drawerEl = qsRequired(`[data-uc-slider-drawer="${this.drawerId}"]`, document.body)
		this.drawerContent = qsRequired('[data-uc-drawer-content]', this.drawerEl)
		this.openButton = qsRequired('[data-uc-open-drawer]', this)
		this.headerDrawer = qsRequired('header-drawer', document.body)
		this.predictiveSearch = qsOptional('predictive-search', this.drawerEl)
		this.facetsForm = qsOptional('facet-filters-form', this.drawerEl)
		this.closeAllButton = qsOptional('[data-uc-close-all]', this.drawerEl)
		this.addEventListener('keyup', this.onKeyUp.bind(this))
		this.drawerContent.addEventListener('focusout', this.onFocusOut.bind(this))
		this.bindEvents()
	}

	bindEvents() {
		this.openButton.addEventListener(
			'click',
			debounce(() => {
				this.openSlideDrawer()
			}, 100).bind(this)
		)
		if (this.closeAllButton) {
			this.closeAllButton.addEventListener('click', (event: MouseEvent | TouchEvent) => {
				this.onCloseButtonClick(event)
			})
		}
	}

	onKeyUp(event: KeyboardEvent) {
		if (event.code.toUpperCase() !== 'ESCAPE') return
		this.closeSlideDrawer(qsOptional('summary', this.openButton))
	}

	openSlideDrawer() {
		this.openButton.setAttribute('aria-expanded', 'true')
		this.drawerEl.setAttribute('open', '')
		if (this.predictiveSearch) {
			trapFocus(this.drawerEl, qsRequired('input', this.predictiveSearch))
		} else if (this.facetsForm) {
			trapFocus(this.drawerEl, qsRequired('summary', qsRequired('form', this.facetsForm)))
		}

		// timeout to prevent overflow hidden from other drawers closing firing before this one opens
		setTimeout(() => {
			document.body.classList.add(`overflow-hidden-${this.dataset.breakpoint}`)
		}, 101)
	}

	closeSlideDrawer(elementToFocus?: HTMLElement) {
		this.drawerEl.removeAttribute('open')
		void this.drawerEl.offsetWidth // hack to enforce redraw
		removeTrapFocus(elementToFocus)
		document.body.classList.remove(`overflow-hidden-${this.dataset.breakpoint}`)
	}

	onFocusOut() {
		setTimeout(() => {
			if (
				this.drawerEl.hasAttribute('open') &&
				!this.drawerEl.contains(document.activeElement)
			)
				this.closeSlideDrawer()
		})
	}

	onCloseButtonClick(event: MouseEvent | TouchEvent) {
		const currentTarget = currentTargetRequired(event)
		if (currentTarget.hasAttribute('data-uc-close-all')) {
			this.closeSlideDrawer()
		}
	}
}
