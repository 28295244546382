import { UcoastEl } from '@/scripts/core/UcoastEl'
import {
	getAttributeOrThrow,
	getCurrentHeaderHeight,
	qsaOptional,
	qsaRequired,
	qsRequired,
} from '@/scripts/functions'

export class DynamicContent extends UcoastEl {
	static htmlSelector = 'dynamic-content'
	items: NodeListOf<HTMLElement>
	nav: HTMLElement
	container: HTMLElement
	scrolled: boolean
	observer: IntersectionObserver
	activeSectionId: string
	mql: MediaQueryList
	constructor() {
		super()
		this.items = qsaRequired('[data-uc-dynamic-content-section]', this)
		this.nav = qsRequired('[data-uc-dynamic-content-nav]', this)
		this.container = qsRequired('[data-uc-dynamic-content-container]', this)
		this.scrolled = false
		this.observer = this.createObserver()
		this.activeSectionId = getAttributeOrThrow('data-uc-dynamic-content-section', this.items[0])
		this.mql = window.matchMedia('(max-width: 749px)')
	}

	override connectedCallback() {
		super.connectedCallback()
		const breakpointChangeHandler = () => {
			this.init()
		}

		this.mql.addEventListener('change', breakpointChangeHandler)
		breakpointChangeHandler()
		this.init()
	}

	init() {
		const layout = this.getLayout()
		this.buildNav(layout)
		this.setActiveSection(this.activeSectionId)
		const elsToObserve = qsaOptional('[data-uc-dynamic-content-scroll-observer]', this)
		if (!elsToObserve) return
		elsToObserve.forEach((section) => {
			this.observer.observe(section)
		})
	}

	buildNav(layout: 'above' | 'sidebar') {
		const tempEl = document.createElement('div')
		this.items.forEach((item) => {
			const button = document.createElement('button')
			button.innerText = qsRequired('[data-uc-dynamic-content-title]', item).innerText
			if (layout === 'sidebar') {
				button.classList.add('cta-text', 'semantic-button', 'left', 'relative')
			} else {
				button.classList.add('button', 'button--sm', 'button--dark')
			}

			button.setAttribute(
				'data-uc-dynamic-content-nav',
				getAttributeOrThrow('data-uc-dynamic-content-section', item)
			)
			tempEl.append(button)
		})
		this.nav.classList.add(`dynamic-content__nav--${layout}`)
		this.nav.innerHTML = tempEl.innerHTML
		const domButtons = qsaRequired('button', this.nav)
		domButtons.forEach((button, index) => {
			const item = this.items[index]
			if (!item) return
			button.addEventListener('click', () =>
				this.goToSection(getAttributeOrThrow('data-uc-dynamic-content-section', item))
			)
		})
	}

	getLayout(): 'above' | 'sidebar' {
		if (this.mql.matches || this.getAttribute('data-uc-dynamic-content-layout') === 'above') {
			return 'above'
		} else {
			return 'sidebar'
		}
	}

	override disconnectedCallback() {
		super.disconnectedCallback()
		this.observer.disconnect()
	}

	createObserver() {
		const observerOptions = {
			root: null,
			threshold: 0,
		}

		const observerCallback = (entries: IntersectionObserverEntry[]) => {
			entries.forEach(() => {
				this.getCurrentSection()
			})
		}

		return new IntersectionObserver(observerCallback, observerOptions)
	}

	getCurrentSection() {
		let closestSectionID: string | undefined
		let closestSectionDistance = Number.MAX_SAFE_INTEGER
		const navTop = this.nav.getBoundingClientRect().top

		this.items.forEach((item) => {
			const distance = item.getBoundingClientRect().bottom - navTop - 50
			if (distance < closestSectionDistance && distance > -2) {
				closestSectionID = getAttributeOrThrow('data-uc-dynamic-content-section', item)
				closestSectionDistance = distance
			}
		})
		if (!closestSectionID || closestSectionID === this.activeSectionId) return
		this.setActiveSection(closestSectionID)
	}

	setActiveSection(id: string) {
		this.activeSectionId = id
		const activeButton = qsRequired(`[data-uc-dynamic-content-nav="${id}"]`)
		qsaRequired('button', this.nav).forEach((button) => {
			button.classList.remove('active')
		})
		activeButton.classList.add('active')
		if (!this.mql.matches) return
		// Scroll nav to active button on mobile
		const targetPosition = activeButton.offsetLeft
		const navStyle = getComputedStyle(this.nav)
		const paddingLeft = parseInt(navStyle.paddingLeft, 10) || 0
		this.nav.scroll({
			left: targetPosition - paddingLeft,
			behavior: 'smooth',
		})
	}

	goToSection(id: string) {
		const section = qsRequired(`[data-uc-dynamic-content-section="${id}"]`, this)
		if (this.getAttribute('data-uc-dynamic-content-layout') === 'above') {
			const url = section.getAttribute('data-uc-dynamic-content-url')
			if (url) {
				history.pushState({}, '', url)
			}
			this.items.forEach((item) => {
				item.classList.remove('active')
			})
			section.classList.add('active')
			this.activeSectionId = id
			const activeButton = qsRequired(`[data-uc-dynamic-content-nav="${id}"]`)
			qsaRequired('button', this.nav).forEach((button) => {
				button.classList.remove('active')
			})
			activeButton.classList.add('active')
		} else {
			const headerHeight = getCurrentHeaderHeight()
			const location = section.getBoundingClientRect().top + window.scrollY - headerHeight
			window.scrollTo({
				top: location,
				behavior: 'smooth',
			})
		}
	}
}
